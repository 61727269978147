import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaBoxOpen, FaUser, FaTimes } from 'react-icons/fa';
import logo from "../../assets/logo.png";
import TooltipIcon from '../TooltipWrapper';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <nav className={`fixed top-0 left-0 w-full text-black py-4 px-5 sm:px-8 flex items-center justify-between lg:justify-around font-['Urbanist'] z-50 bg-white shadow-lg transition-all duration-500 ease-in-out transform ${showNavbar ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>
        <div className="flex items-center">
          <img 
            src={logo} 
            alt="JHS Media" 
            className="h-12 w-auto cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-110" 
            onClick={() => navigate('/')} 
          />
        </div>
        <div className="hidden sm:flex items-center space-x-4 sm:space-x-10">
          <TooltipIcon 
            title="View Orders" 
            icon={FaBoxOpen} 
            link="/orders" 
            badge="3"
          />
          <TooltipIcon 
            title="View Cart" 
            icon={FaShoppingCart} 
            link="/cart" 
            badge="5"
          />
          <TooltipIcon 
            title="Profile" 
            icon={FaUser} 
            link="/profile"
          />
        </div>

        <button
          className={`sm:hidden flex items-center ${isOpen ? 'bg-black text-white' : ''} p-2 rounded-full transition duration-300 ease-in-out`}
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
            />
          </svg>
        </button>

        {isOpen && (
          <div className="absolute top-0 left-0 w-full h-auto bg-black p-5 flex flex-col items-center text-white shadow-lg sm:hidden">
            <button className="self-end mb-5" onClick={toggleMenu}>
              <FaTimes className="w-6 h-6" />
            </button>
            <Link to="/orders" className="mb-4 text-lg" onClick={toggleMenu}>Orders</Link>
            <Link to="/cart" className="mb-4 text-lg" onClick={toggleMenu}>Cart</Link>
            <Link to="/profile" className="text-lg" onClick={toggleMenu}>Profile</Link>
          </div>
        )}
      </nav>

      <div className="pt-[80px]"> 
      </div>
    </>
  );
}

export default Navbar;
