import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import img5 from "../../assets/img-5.png";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  Breadcrumbs,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/system';

const orders = [
  {
    id: 'ORD123456',
    date: 'August 29, 2024',
    items: [
      {
        name: 'Gold Necklace',
        quantity: 1,
        image: img5,
      },
      {
        name: 'Diamond Ring',
        quantity: 2,
        image: 'https://via.placeholder.com/50',
      },
      {
        name: 'Diamond Ring',
        quantity: 2,
        image: 'https://via.placeholder.com/50',
      },
      {
        name: 'Diamond Ring',
        quantity: 2,
        image: 'https://via.placeholder.com/50',
      },
      // Add more items if needed
    ],
    total: '$1299.99',
    status: 'Shipped',
  },
  {
    id: 'ORD654321',
    date: 'August 25, 2024',
    items: [
      {
        name: 'Silver Bracelet',
        quantity: 1,
        image: 'https://via.placeholder.com/50',
      },
      {
        name: 'Pearl Earrings',
        quantity: 1,
        image: 'https://via.placeholder.com/50',
      },
    ],
    total: '$499.99',
    status: 'Delivered',
  },
  // Add more orders as needed
];

const statusColors = {
  Delivered: {
    backgroundColor: '#e0f7fa',
    color: '#00796b',
    buttonColor: '#00796b',
    buttonHover: '#004d40',
  },
  Shipped: {
    backgroundColor: '#fff9c4',
    color: '#f57f17',
    buttonColor: '#f57f17',
    buttonHover: '#e65100',
  },
  Default: {
    backgroundColor: '#ffcdd2',
    color: '#c62828',
    buttonColor: '#c62828',
    buttonHover: '#b71c1c',
  },
};

const StatusBadge = styled(Box)(({ status }) => ({
  backgroundColor: statusColors[status]?.backgroundColor || statusColors.Default.backgroundColor,
  color: statusColors[status]?.color || statusColors.Default.color,
  padding: '0.5rem 1rem',
  borderRadius: '1rem',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.875rem',
}));

const ViewButton = styled(Button)(({ status }) => ({
  backgroundColor: statusColors[status]?.buttonColor || statusColors.Default.buttonColor,
  color: '#fff',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: statusColors[status]?.buttonHover || statusColors.Default.buttonHover,
  },
  padding: '6px 12px',
  fontSize: '0.875rem',
  borderRadius: '0.5rem',
}));

const OrdersPage = () => {
  const navigate = useNavigate();

  // Manage the expanded state for each order by its id
  const [expandedOrder, setExpandedOrder] = useState({});

  const handleToggleItems = (orderId) => {
    setExpandedOrder((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const handleViewDetails = (order) => {
    navigate(`/order_details/${order.id}`, { state: { order } });
  };

  return (
    <Box
      minHeight="100vh"
      bgcolor="background.default"
      py={5}
      px={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxWidth="md" width="100%">
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Home
          </Link>
          <Typography color="text.primary">My Orders</Typography>
        </Breadcrumbs>
        <Typography 
          variant="h4" 
          component="h1" 
          fontWeight="bold" 
          textAlign="center" 
          gutterBottom
          sx={{ 
            fontSize: '2rem',
            lineHeight: '2.5rem',
            marginBottom: '1.5rem',
            color: '#333',
            '@media (max-width:600px)': {
              fontSize: '1.75rem',
              lineHeight: '2.25rem',
            },
          }}
        >
          My Orders
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {orders.map((order) => {
            const isExpanded = expandedOrder[order.id] || false;
            return (
              <Grid item xs={12} key={order.id}>
                <Card
                  variant="outlined"
                  sx={{
                    p: 2,
                    mx: 'auto',
                    width: '100%',
                    maxWidth: '900px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s ease',
                    position: 'relative',
                    '&:hover': {
                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <CardContent sx={{ padding: '8px' }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} md={8}>
                        <Typography 
                          variant="h6" 
                          fontWeight="bold" 
                          gutterBottom
                          sx={{
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            marginBottom: '1rem',
                            color: '#444',
                            '@media (max-width:600px)': {
                              fontSize: '1.125rem',
                              lineHeight: '1.5rem',
                            },
                          }}
                        >
                          Order #{order.id}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          color="textSecondary" 
                          gutterBottom
                          sx={{
                            fontSize: '1rem',
                            lineHeight: '1.5rem',
                            marginBottom: '0.5rem',
                            color: '#666',
                            '@media (max-width:600px)': {
                              fontSize: '0.875rem',
                              lineHeight: '1.25rem',
                            },
                          }}
                        >
                          Date: {order.date}
                        </Typography>
                        <Collapse in={isExpanded} collapsedSize={88}>
                          {order.items.map((item, index) => (
                            <Grid 
                              container 
                              key={index} 
                              spacing={1} 
                              alignItems="center" 
                              sx={{ mb: 1 ,}}
                            >
                              <Grid item > 
                                <CardMedia
                                  component="img"
                                  src={item.image}
                                  alt={item.name}
                                  sx={{ 
                                    height: 48, 
                                    width: 48, 
                                    borderRadius: 1 
                                  }}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography 
                                  variant="subtitle1" 
                                  fontWeight="medium"
                                  sx={{
                                    fontSize: '1rem',
                                    lineHeight: '1.5rem',
                                    color: '#333',
                                    '@media (max-width:600px)': {
                                      fontSize: '0.875rem',
                                      lineHeight: '1.25rem',
                                    },
                                  }}
                                >
                                  {item.name} ({item.quantity})
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Collapse>
                        {order.items.length > 2 && (
                          <Button
                            size="small"
                            onClick={() => handleToggleItems(order.id)}
                            sx={{
                              textTransform: 'none',
                              fontSize: '0.875rem',
                              color: '#007bff',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            {isExpanded ? 'Show Less' : `View More (${order.items.length - 2} more)`}
                          </Button>
                        )}
                        <Typography 
                          variant="h6" 
                          fontWeight="bold"
                          sx={{
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            color: '#444',
                            paddingTop:1
                          }}
                        >
                          Total: {order.total}
                        </Typography>
                      </Grid>
                      <Grid 
                        item 
                        xs={12} 
                        md={4} 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="flex-end"
                      >
                        <StatusBadge 
                          status={order.status}
                          sx={{
                            marginBottom: '1rem',
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                            '@media (max-width:600px)': {
                              fontSize: '0.75rem',
                              lineHeight: '1rem',
                            },
                          }}
                        >
                          {order.status}
                        </StatusBadge>
                        <ViewButton
                          status={order.status}
                          sx={{ 
                            mt: 1,
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                            '@media (max-width:600px)': {
                              fontSize: '0.75rem',
                              lineHeight: '1rem',
                            },
                          }}
                          size="small"
                          onClick={() => handleViewDetails(order)}
                        >
                          View Details
                        </ViewButton>
                        <Box
  sx={{
    position: 'absolute',
    top: '20px',
    right: '50px',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007bff',
    padding: '4px 10px',
    borderRadius: '3px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    transform: 'translateX(10px)',
  }}
>
  {order.items.reduce((total, item) => total + item.quantity, 0)} Items
</Box>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default OrdersPage;
