import React, { useEffect, useState } from "react";

const QuantityModal = ({ isOpen, onClose, onSelectQuantity, selectedQuantity }) => {
  const [quantity, setQuantity] = useState(selectedQuantity || ""); // Set initial state to an empty string
  const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const handleInputChange = (e) => {
    const value = Math.max(1, Math.min(100, parseInt(e.target.value) || "")); // Remove default, handle as string or number
    setQuantity(value);
  };

  const handleConfirm = () => {
    if (quantity) {
      onSelectQuantity(quantity);
      onClose();
    } else {
      alert("Please select or enter a quantity.");
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-start justify-end z-50 bg-black bg-opacity-50">
          <div className="bg-white w-full sm:w-[400px] h-full p-6 rounded-l-xl rounded-t-xl relative flex flex-col justify-between shadow-xl">
            <button
              className="absolute top-3 right-3 text-gray-600"
              onClick={onClose}
            >
              &times;
            </button>
            <div>
              <div className="text-center mb-6">
                <h3 className="text-xl font-medium mb-2">₹3,19,310</h3>
                <p className="text-sm text-gray-500">Select Quantity</p>
              </div>
              <div className="grid grid-cols-5 gap-2 mb-6">
                {quantities.map((qty) => (
                  <button
                    key={qty}
                    className={`p-3 rounded-lg border ${
                      quantity === qty
                        ? 'bg-purple-100 border-purple-300'
                        : 'border-gray-300'
                    }`}
                    onClick={() => handleQuantityChange(qty)}
                  >
                    {qty}
                  </button>
                ))}
              </div>
              <div className="mb-6">
                <input
                  type="number"
                  min="1"
                  max="100"
                //   value={quantity}
                  onChange={handleInputChange}
                  placeholder="Enter quantity"
                  className="w-full p-3 border rounded-lg text-center"
                />
              </div>
            </div>
            <div className="mt-6">
              <button
                className="w-full bg-purple-700 text-white py-3 rounded-lg hover:bg-purple-800 transition"
                // onClick={handleConfirm}
              >
                CONFIRM QUANTITY
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuantityModal;
