import React, { useEffect, useState } from 'react';
import { handlegetAllCategories, handlegetAllVarients } from '../../contextApi/api';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';

const Filters = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    categories: [],
    variants: [],
  });
  const [categories, setCategories] = useState([]);
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllCategories();
    fetchAllVariants();
  }, []);

  const fetchAllCategories = async () => {
    try {
      setLoading(true);
      const res = await handlegetAllCategories();
      if (res?.data?.data && res.data.data.data.length > 0) {
        setCategories(res.data.data.data);
      } else {
        setCategories([]); // Empty array if no categories are returned
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllVariants = async () => {
    try {
      setLoading(true);
      const res = await handlegetAllVarients();
      if (res?.data?.data && res.data.data.data.length > 0) {
        setVariants(res.data.data.data);
      } else {
        setVariants([]); // Empty array if no variants are returned
      }
    } catch (error) {
      console.error('Failed to fetch variants:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event) => {
    const categoryName = event.target.name;
    const updatedCategories = filters.categories.includes(categoryName)
      ? filters.categories.filter((name) => name !== categoryName)
      : [...filters.categories, categoryName];

    setFilters((prevFilters) => ({
      ...prevFilters,
      categories: updatedCategories,
    }));

    onFilterChange({ ...filters, categories: updatedCategories });
  };

  const handleVariantChange = (event) => {
    const variantName = event.target.name;
    const updatedVariants = filters.variants.includes(variantName)
      ? filters.variants.filter((name) => name !== variantName)
      : [...filters.variants, variantName];

    setFilters((prevFilters) => ({
      ...prevFilters,
      variants: updatedVariants,
    }));

    onFilterChange({ ...filters, variants: updatedVariants });
  };

  const handleClearAll = () => {
    const clearedFilters = {
      categories: [],
      variants: [],
    };
    setFilters(clearedFilters);
    onFilterChange(clearedFilters);
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md w-full sm:w-64 mb-4 sm:mb-0">
      <div className="flex justify-between items-center">
        <h2 className="font-semibold text-lg">FILTERS</h2>
        <Button
          onClick={handleClearAll}
          className="text-red-500 font-semibold"
          disabled={filters.categories.length === 0 && filters.variants.length === 0} // Disable when no categories or variants are selected
        >
          CLEAR ALL
        </Button>
      </div>

      <div className="mt-4">
        <h3 className="font-semibold mb-2">Categories</h3>
        {loading ? (
          <div className="space-y-2">
            <Skeleton variant="text" width="75%" height={30} />
            <Skeleton variant="text" width="50%" height={30} />
          </div>
        ) : categories.length > 0 ? (
          <FormGroup>
            {categories.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    checked={filters.categories.includes(category.name)}
                    onChange={handleCategoryChange}
                    name={category.name}
                  />
                }
                label={category.name}
              />
            ))}
          </FormGroup>
        ) : (
          <p className="text-gray-700">No categories available.</p>
        )}
      </div>

      <div className="mt-4">
        <h3 className="font-semibold mb-2">Variants</h3>
        {loading ? (
          <div className="space-y-2">
            <Skeleton variant="text" width="75%" height={30} />
            <Skeleton variant="text" width="50%" height={30} />
          </div>
        ) : variants.length > 0 ? (
          <FormGroup>
            {variants.map((variant) => (
              <FormControlLabel
                key={variant.id}
                control={
                  <Checkbox
                    checked={filters.variants.includes(variant.name)}
                    onChange={handleVariantChange}
                    name={variant.name}
                  />
                }
                label={variant.name}
              />
            ))}
          </FormGroup>
        ) : (
          <p className="text-gray-700">No variants available.</p>
        )}
      </div>
    </div>
  );
};

export default Filters;
