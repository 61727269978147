import React, { useState } from 'react';
import { FaRupeeSign, FaCoins } from 'react-icons/fa'; // Importing icons from react-icons
import { Button, Card, CardContent, Typography, Grid, Divider, Skeleton } from '@mui/material'; // Importing Material-UI components

const Transaction = () => {
  const [selected, setSelected] = useState('rupee'); // State to track the selected icon
  const [loading, setLoading] = useState(false); // State to control loading
  const [showTransactions, setShowTransactions] = useState(false); // State to control animation

  const transactions = {
    rupee: [
      { id: 1, date: 'Aug 31, 2024', amount: '500', type: 'Debit', status: 'Completed' },
      { id: 2, date: 'Aug 30, 2024', amount: '1000', type: 'Credit', status: 'Completed' },
    ],
    coins: [
      { id: 3, date: 'Aug 29, 2024', amount: '750', type: 'Debit', status: 'Pending' },
      { id: 4, date: 'Aug 28, 2024', amount: '1500', type: 'Credit', status: 'Completed' },
    ],
  };

  const handleSelect = (type) => {
    setSelected(type); // Set the selected type
    setShowTransactions(false); // Hide transactions
  };

  const handleShowTransactions = () => {
    setLoading(true); // Start loading
    setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
      setShowTransactions(true); // Show transactions smoothly
    }, 2000); // 2 seconds loading time
  };

  return (
    <div className="w-3/4 mx-auto mt-10 mb-10"> {/* Added bottom margin for consistent spacing */}
      <div className="flex items-center justify-around py-5 px-10 bg-white rounded-lg shadow-md">
        <div
          className={`flex items-center space-x-2 cursor-pointer ${
            selected === 'rupee' ? 'bg-blue-100' : ''
          } p-2 rounded-md`}
          onClick={() => handleSelect('rupee')}
        >
          <FaRupeeSign className={`text-2xl ${selected === 'rupee' ? 'text-blue-500' : 'text-gray-700'}`} />
          <div className="bg-gray-100 text-gray-700 font-semibold text-lg px-4 py-1 rounded-md shadow-sm">
            ₹1000
          </div>
        </div>
        <div
          className={`flex items-center space-x-2 cursor-pointer ${
            selected === 'coins' ? 'bg-blue-100' : ''
          } p-2 rounded-md`}
          onClick={() => handleSelect('coins')}
        >
          <FaCoins className={`text-2xl ${selected === 'coins' ? 'text-blue-500' : 'text-yellow-500'}`} />
          <div className="bg-gray-100 text-gray-700 font-semibold text-lg px-4 py-1 rounded-md shadow-sm">
            1000
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={handleShowTransactions}>
          See Transactions
        </Button>
      </div>

      <div className={`mt-10 transition-opacity duration-500 ease-in-out`}>
        {/* Heading appears only after loading is complete */}
        {showTransactions && (
          <Typography
            variant="h5"
            component="div"
            className="text-center mb-6 font-bold animate-fadeIn"
            style={{ fontFamily: 'Urbanist, sans-serif', transition: 'opacity 0.5s ease-in-out' }}
          >
            {selected === 'rupee' ? 'Rupee Transactions' : 'Coins Transactions'}
          </Typography>
        )}
        <div style={{ minHeight: '200px' }}> {/* Set a minimum height to avoid shifting */}
          {loading ? (
            <Grid container spacing={3}>
              {[1, 2, 3, 4].map((item) => (
                <Grid item xs={12} key={item}>
                  <Card className="shadow-lg rounded-lg">
                    <CardContent>
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={200} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            showTransactions && (
              <Grid container spacing={3}>
                {transactions[selected].map((transaction) => (
                  <Grid item xs={12} key={transaction.id}>
                    <Card className="shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                      <CardContent>
                        <div className="flex justify-between items-center">
                          <div>
                            <Typography variant="h6" component="div" className="text-gray-700" style={{ fontFamily: 'Urbanist, sans-serif', fontWeight: '600' }}>
                              {transaction.date}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Urbanist, sans-serif', fontSize: '14px' }}>
                              Amount: ₹{transaction.amount}
                            </Typography>
                          </div>
                          <div className="text-right">
                            <Typography variant="body2" className={`font-semibold ${transaction.type === 'Credit' ? 'text-green-600' : 'text-red-600'}`} style={{ fontFamily: 'Urbanist, sans-serif', fontWeight: '600' }}>
                              {transaction.type}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Urbanist, sans-serif', fontSize: '14px' }}>
                              Status: {transaction.status}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                      <Divider />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Transaction;
