

import ProductList from './ProductList';
import Category from '../../component/navbar/category';

const DigitalInfluencerSection = () => {
  return (
    <>
        <Category/>
      <ProductList/>
    </>
  );
};

export default DigitalInfluencerSection;
