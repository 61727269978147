import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { handleGetProfile } from '../../contextApi/api';

const steps = ['Shipping Information', 'Review & Confirm'];

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [shippingInfo, setShippingInfo] = useState({
    fullName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await handleGetProfile();
        if (res.data.status) {
          const data = res.data.data;
          setShippingInfo({
            fullName: data.name,
            address1: data.address || '',
            address2: data.address2 || '',
            city: data.city || '',
            state: data.state || '',
            zip: data.zipcode || '',
            country: data.country || '',
            phone: data.mobile,
          });
        }
      } catch (error) {
        console.error('Failed to fetch profile', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
        navigate('/cart');
      } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const inputFields = [
    { label: 'Full Name', name: 'fullName' },
    { label: 'Address Line 1', name: 'address1' },
    { label: 'Address Line 2', name: 'address2' },
    { label: 'City', name: 'city' },
    { label: 'State/Province', name: 'state' },
    { label: 'ZIP/Postal Code', name: 'zip' },
    { label: 'Country', name: 'country' },
    { label: 'Phone Number', name: 'phone' },
  ];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            {inputFields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index}>
                {loading ? (
                  <Skeleton variant="rectangular" height={56} />
                ) : (
                  <TextField
                  label={field.label}
                  name={field.name}
                  fullWidth
                  variant="outlined"
                  value={shippingInfo[field.name]}
                  sx={{
                    input: {
                      color: '#9e9e9e', // Dark black text color
                      backgroundColor: '#f5f5f5',
                      borderRadius: '8px',
                      fontWeight: 'bold', // Make the text bold
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: '#000', // Ensures the label is also dark black
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
                
                )}
              </Grid>
            ))}
          </Grid>
        );
      case 1:
        return (
          <Paper elevation={0} className="p-4" style={{ marginTop: '24px', width: '100%' }}>
           <Typography
  variant="h5" // Larger font size for emphasis
  style={{
    fontWeight: 'bold',
    color: '#424242',
    marginBottom: '16px',
    // borderBottom: '2px solid #e0e0e0',
    paddingBottom: '8px',
  }}
>
  Review Your Order
</Typography>


            <Divider className="mb-4" />
            <List>
            <ListItem>
  <ListItemText
    primary={
      <Typography
      variant="h7" // Slightly smaller than "Review Your Order"
      style={{
        fontWeight: 'bold',
        color: '#424242',
        marginBottom: '8px',
      }}
    >
      Shipping Information
    </Typography>
    
    }
    secondary={
      <div style={{ marginTop: '8px' }}>
        <Typography variant="body1" style={{ fontWeight: 'bold', color: '#424242', marginBottom: '8px' }}>
          {shippingInfo.fullName}
        </Typography>
        <div style={{ paddingLeft: '16px' }}>
          <Typography variant="body2" style={{ color: '#616161', marginBottom: '4px' }}>
            {shippingInfo.address1}
          </Typography>
          {shippingInfo.address2 && (
            <Typography variant="body2" style={{ color: '#616161', marginBottom: '4px' }}>
              {shippingInfo.address2}
            </Typography>
          )}
          <Typography variant="body2" style={{ color: '#616161', marginBottom: '4px' }}>
            {shippingInfo.city}, {shippingInfo.state} {shippingInfo.zip}
          </Typography>
          <Typography variant="body2" style={{ color: '#616161', marginBottom: '4px' }}>
            {shippingInfo.country}
          </Typography>
          <Typography variant="body2" style={{ color: '#616161' }}>
            Phone: {shippingInfo.phone}
          </Typography>
        </div>
      </div>
    }
  />
</ListItem>

              <Divider component="li" />
              <ListItem>
  <ListItemText
    primary={
      <Typography
      variant="h7" // Matching the size of "Shipping Information"
      style={{
        fontWeight: 'bold',
        color: '#424242',
        marginBottom: '8px',
      }}
    >
      Order Summary
    </Typography>
    
    }
    secondary={
      <>
        <div style={{ marginTop: '8px' , paddingLeft: '16px'}}>
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '8px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Subtotal:
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: '#616161' }}>
              $99.99
            </Typography>
          </Grid>
          <Divider style={{ marginBottom: '8px' }} />
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '8px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Shipping:
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: '#616161' }}>
              $5.99
            </Typography>
          </Grid>
          <Divider style={{ marginBottom: '8px' }} />
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '8px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Tax:
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: '#616161' }}>
              $2.50
            </Typography>
          </Grid>
          <Divider style={{ marginBottom: '8px' }} />
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '8px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Items:
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: '#616161' }}>
              6
            </Typography>
          </Grid>
          <Divider style={{ marginBottom: '16px' }} />
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#424242' }}>
              Total:
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#424242' }}>
              $108.48
            </Typography>
          </Grid>
        </div>
      </>
    }
  />
</ListItem>


            </List>
          </Paper>
        );
      default:
        return navigate('/ordersuccess');
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '40px', marginBottom: '40px' }}>
      <Paper
        elevation={3}
        className="p-4"
        style={{
          minHeight: '600px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '24px',
          borderRadius: '16px',
        }}
      >
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Checkout
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {renderStepContent(activeStep)}
        </div>
        <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleBack} variant="outlined" style={{ marginRight: '8px' }}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Place Order' : 'Next'}
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default CheckoutPage;
