import React, { useState } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { handleAddCart, handleRemoveCart } from "../../../contextApi/api";
import { toast } from "react-toastify";
import CustomSnackbar from "../../../component/CustomSnackbar";
import { handleFallbackImage } from "../../../component/fallBackImg";

const ProductCard = ({ image, title, price, productId, product, categoryName, variationName }) => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(false);

  const addToCart = async (newQuantity) => {
    setLoading(true);
    const cartItem = {
      products: [
        {
          skus_id: productId,
          pcs: 1,
        },
      ],
    };

    try {
      const res = await handleAddCart(cartItem);
      if (res?.data?.status) {
        handleSnackbarOpen(res?.data?.message);
        setQuantity(newQuantity);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to add to cart");
    } finally {
      setLoading(false);
    }
  };

  const removeCart = async (newQuantity) => {
    setLoading(true);
    const data = {
      cart_id: productId,
    };

    try {
      const res = await handleRemoveCart(data);
      if (res?.data?.status) {
        handleSnackbarOpen(res?.data?.message);
        setQuantity(newQuantity);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to remove from cart");
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = async () => {
    setScale(0.9); // Slightly shrink
    await addToCart(1);
    setScale(1); // Return to normal size
  };

  const increaseQuantity = async () => {
    setScale(0.9); // Slightly shrink
    const newQuantity = quantity + 1;
    await addToCart(newQuantity);
    setScale(1); // Return to normal size
  };

  const decreaseQuantity = async () => {
    if (quantity > 1) {
      setScale(0.9); // Slightly shrink
      const newQuantity = quantity - 1;
      await removeCart(newQuantity);
      setScale(1); // Return to normal size
    } else {
      setScale(0.9);
      setTimeout(async () => {
        await removeCart(0);
        setScale(1); // Return to normal size
      }, 150);
    }
  };

  const handleViewProduct = () => {
    navigate(`/product_details/${productId}`);
  };

  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: '',
    duration: 2500,
  });

  const handleSnackbarOpen = (message) => {
    setSnackbarState({
      open: true,
      message: message,
      duration: 800,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <>
      <div
        className="w-full sm:w-[450px] h-full bg-white shadow-md rounded-lg overflow-hidden mt-1 transition-transform duration-300 ease-in-out"
        style={{ transform: `scale(${scale})`, opacity: loading ? 0.7 : 1 }}
      >
        <div className="bg-gray-100 h-48 sm:h-64 flex items-center justify-center">
          <img 
            src={image} 
            alt={title} 
            className="w-full h-full object-cover"
            onError={(e) => e.target.src = handleFallbackImage()}
            loading="lazy"
          />
        </div>
        <div className="p-4 sm:p-5">
          <div className="flex justify-between items-center">
            <h3 className="text-gray-800 text-lg sm:text-xl font-semibold">{title}</h3>
            <p className="text-[#b6c1d4] text-xs sm:text-sm font-medium bg-[#4c5a70] py-1 px-2 rounded-lg cursor-no-drop">
              {categoryName}
            </p>
          </div>
          <div className="flex justify-between items-center mt-3 sm:mt-4">
            <p className="text-gray-900 text-xl sm:text-2xl font-bold flex items-center">
              <FaRupeeSign className="mr-1" />
              {price}
            </p>
            <p className="text-gray-500 text-xs sm:text-sm italic bg-gray-100 py-1 px-2 rounded-lg">
              {variationName}
            </p>
          </div>
          <div className="flex space-x-2 mt-4 mb-1">
            {quantity > 0 ? (
              <div
                className={`relative flex-1 flex items-center justify-between bg-gray-200 text-gray-800 rounded-md border border-gray-300 transition-transform duration-300 ease-in-out ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <button
                  className="p-2 flex items-center justify-center hover:bg-gray-300 transition duration-300 w-1/3 rounded-l-md"
                  onClick={decreaseQuantity}
                  disabled={loading}
                >
                  <AiOutlineMinus className="h-5 w-5 text-gray-600" />
                </button>
                <span className="p-2 text-center w-1/3">{quantity}</span>
                <button
                  className="p-2 flex items-center justify-center hover:bg-gray-300 transition duration-300 w-1/3 rounded-r-md"
                  onClick={increaseQuantity}
                  disabled={loading}
                >
                  <AiOutlinePlus className="h-5 w-5 text-gray-600" />
                </button>
                {loading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <ClipLoader color={"#000"} size={24} />
                  </div>
                )}
              </div>
            ) : (
              <button
                className={`relative flex-1 py-2 bg-black text-white rounded-md flex items-center justify-center hover:bg-gray-800 transition-transform duration-300 ease-in-out ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleAddToCart}
                disabled={loading}
                style={{ transform: `scale(${scale})`, opacity: loading ? 0.7 : 1 }}
              >
                {loading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <ClipLoader color={"#fff"} size={24} />
                  </div>
                ) : (
                  <>
                    <FiShoppingCart className="h-5 w-5 mr-2" />
                    Add to Cart
                  </>
                )}
              </button>
            )}
            <button
              className="flex-1 py-2 bg-gray-200 text-gray-800 rounded-md flex items-center justify-center hover:bg-gray-300 transition duration-300"
              onClick={handleViewProduct}
            >
              View Product
            </button>
          </div>
        </div>
      </div>
      <CustomSnackbar
        open={snackbarState.open}
        handleClose={handleSnackbarClose}
        message={snackbarState.message}
        duration={snackbarState.duration}
      />
    </>
  );
};

export default ProductCard;
