import React from 'react';
import { Dialog, DialogContent, Button, IconButton } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';

const MoveDesignModal = ({ isOpen, onClose, onRemove, imageUrl }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: { borderRadius: 20 }, // Rounded corners for the modal
      }}
    >
      <DialogContent className="relative p-6">
        <IconButton
          onClick={onClose}
          className="absolute top-2 left-[350px] text-gray-500 hover:text-gray-700"
          size="large"
        >
          <AiOutlineClose className="w-6 h-6" />
        </IconButton>
        <div className="flex flex-col items-center">
          <img
            src={imageUrl}
            alt="Design"
            className="w-24 h-24 rounded-full object-cover mb-4"
          />
          <h2 className="text-lg font-semibold mb-2">Delete Product from Cart</h2>
          <p className="text-gray-500 mb-6 text-center">
            Are you sure you want to delete this product from your cart?
          </p>
          <div className="flex space-x-4">
            <Button
              onClick={onClose}
              variant="outlined"
              className="border-gray-500 text-gray-500 rounded-full hover:bg-gray-50"
            >
              CANCEL
            </Button>
            <Button
              onClick={onRemove}
              variant="contained"
              className="bg-red-500 text-white rounded-full hover:bg-red-600"
            >
              DELETE
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MoveDesignModal;
