import React, { useState } from "react";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai"; // Import down arrow and close icons
import QuantityModal from "./QuantityModal"; // Import your QuantityModal component
import MoveDesignModal from "./MoveDesignModal"; // Import your MoveDesignModal component
import { URL } from "../../contextApi/urls";
import { handleFallbackImage } from "../../component/fallBackImg";

const CartItem = ({ product, onRemove, onUpdateQuantity }) => {
  const [isQuantityModalOpen, setQuantityModalOpen] = useState(false);
  const [isMoveDesignModalOpen, setMoveDesignModalOpen] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(product?.pcs);

  // Ensure the price is treated as a number
  const productPrice = parseFloat(product?.price || 0);
  const totalPrice = (productPrice * selectedQuantity).toFixed(2);

  const handleQuantityChange = (newQuantity) => {
    setSelectedQuantity(newQuantity);
    setQuantityModalOpen(false);
    onUpdateQuantity(product.id, newQuantity); // Pass the new quantity back to parent component
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-lg flex flex-col md:flex-row items-center px-4 md:px-10 py-4 md:py-6 relative">
        {/* Close icon for removing item */}
        <button
          className="absolute top-2 right-2 text-red-500 hover:text-red-700 focus:outline-none"
          onClick={() => setMoveDesignModalOpen(true)}
        >
          <AiOutlineClose className="h-6 w-6" />
        </button>

        <img
          src={`${URL.BASE_URL}${product?.sku?.product?.featureimage}`}
          alt={product.name}
          className="w-20 h-20 object-cover rounded-lg mb-4 md:mb-0"
          onError={(e)=>e.target.src = handleFallbackImage()}
        />
        <div className="ml-0 md:ml-4 flex-1 text-center md:text-left">
          <h4 className="font-bold text-lg">{product?.sku?.product.name}</h4>
          <span className="block text-gray-700 mt-1">${productPrice.toFixed(2)}</span>
        </div>
        <div className="flex flex-col items-center md:items-start mt-4 md:mt-0 px-0 md:px-20">
          <div className="flex items-center mb-2">
            <span className="text-lg font-semibold">Quantity:</span>
            <button
              className="flex items-center ml-2 text-lg font-bold focus:outline-none"
              onClick={() => setQuantityModalOpen(true)}
            >
              {selectedQuantity}
              <AiOutlineDown className="ml-1 h-4 w-4 text-gray-600" />
            </button>
          </div>
          <div className="text-lg font-semibold">
            Total: <span className="font-bold">${totalPrice}</span>
          </div>
        </div>
      </div>

      {/* Modal for selecting quantity */}
      {isQuantityModalOpen && (
        <QuantityModal
          isOpen={isQuantityModalOpen}
          onClose={() => setQuantityModalOpen(false)}
          onSelectQuantity={handleQuantityChange}
          selectedQuantity={selectedQuantity}
        />
      )}

      {/* Modal for confirming move or remove */}
      {isMoveDesignModalOpen && (
        <MoveDesignModal
          isOpen={isMoveDesignModalOpen}
          onClose={() => setMoveDesignModalOpen(false)}
          onRemove={() => {
            setMoveDesignModalOpen(false);
            onRemove(product.id); // Remove the item after confirming
          }}
          imageUrl={`${URL.BASE_URL}${product?.sku?.product?.featureimage}`} // Pass the image URL here
        />
      )}
    </>
  );
};

export default CartItem;
