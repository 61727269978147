import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Container, Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const PingIconWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  margin: '0 auto 16px',
  backgroundColor: '#FFE0B2', // Hardcoded light orange color
  borderRadius: '50%',
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: '50%',
    backgroundColor: '#FB8C00', // Hardcoded main orange color
    animation: 'ping 1.5s infinite',
    opacity: 0.75,
  },
  '@keyframes ping': {
    '0%': {
      transform: 'scale(1)',
    },
    '75%, 100%': {
      transform: 'scale(2)',
      opacity: 0,
    },
  },
}));

const OrderConfirmation = () => {
  const navigate = useNavigate();

  const handleViewOrder = () => {
    const orderId = 'your-order-id'; // Replace with the actual order ID
    navigate(`/order_details/${orderId}`);
  };

  const handleContinueShopping = () => {
    navigate('/dashboard');
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        minHeight: '100vh',
        bgcolor: 'background.default',
        py: 4,
      }}
    >
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center', marginTop: 5 }}>
        <PingIconWrapper>
          <FaCheckCircle color="#FB8C00" size="40px" />
        </PingIconWrapper>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mt: 4, mb: 2 }}>
          Thank you for ordering!
        </Typography>
        <Typography color="textSecondary" sx={{ mb: 4 }}>
          Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: 'grey.200',
              color: 'text.primary',
              '&:hover': {
                bgcolor: 'grey.300',
              },
            }}
            onClick={handleViewOrder}
          >
            View Order
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: '#FB8C00',
              color: 'white',
              '&:hover': {
                bgcolor: '#EF6C00',
              },
            }}
            onClick={handleContinueShopping}
          >
            Continue Shopping
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default OrderConfirmation;
