import React from 'react';

const Category = () => {
  return (
    <nav className="bg-white pt-10">
      <ul className="flex flex-wrap justify-center space-x-4 md:space-x-8 text-gray-800 text-sm font-semibold">
        <li className="hover:text-gray-500 cursor-pointer">ALL JEWELLERY</li>
        <li className="hover:text-gray-500 cursor-pointer">GOLD</li>
        <li className="hover:text-gray-500 cursor-pointer">DIAMOND</li>
        <li className="hover:text-gray-500 cursor-pointer">EARRINGS</li>
        <li className="hover:text-gray-500 cursor-pointer">RINGS</li>
        <li className="hover:text-gray-500 cursor-pointer">DIGITAL GOLD</li>
        <li className="hover:text-gray-500 cursor-pointer">MIA</li>
        <li className="hover:text-gray-500 cursor-pointer">COLLECTIONS</li>
        <li className="hover:text-gray-500 cursor-pointer">WEDDING</li>
        <li className="hover:text-gray-500 cursor-pointer">GIFTING</li>
        <li className="hover:text-gray-500 cursor-pointer">GOLDEN HARVEST</li>
        <li className="hover:text-gray-500 cursor-pointer">MORE</li>
      </ul>
    </nav>
  );
};

export default Category;
