import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo.png';
import { getToken } from '../../contextApi/token';
import { useNavigate } from 'react-router-dom';
import { handleLogin } from '../../contextApi/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VendorLogin = () => {
    const [data, setData] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false); 
    const navigate = useNavigate();

    const VendorLogin = async (e) => {
        e.preventDefault(); 
        try {
            setIsLoading(true);
            const res = await handleLogin(data);
            if (res?.data?.status) {
                toast.success('Login success');
                navigate('/', { replace: true });
                localStorage.setItem('user', JSON.stringify(res?.data?.data));
            } else {
                toast.error('Login failed');
            }
        } catch (error) {
            console.log(error);
            toast.error('Login failed');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const token = getToken();
        if (token) {
            navigate('/dashboard/', { replace: true });
        }
    }, [navigate]);

    return (
        <section className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-600 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
                <div className="text-center mb-6">
                    <img className="mx-auto h-24 w-auto" src={logo} alt="Vendor Logo" />
                    <h2 className="mt-4 text-3xl font-extrabold text-gray-900">Vendor Login</h2>
                    <p className="mt-2 text-sm text-gray-600">Sign in to manage your products and orders</p>
                </div>
                <form className="space-y-6" onSubmit={VendorLogin}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={data?.email}
                            onChange={(e) => setData({ ...data, email: e.target.value })}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="you@vendor.com"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={data?.password}
                            onChange={(e) => setData({ ...data, password: e.target.value })}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="••••••••"
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember"
                                name="remember"
                                type="checkbox"
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
                                Remember me
                            </label>
                        </div>
                        <div className="text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Forgot your password?
                            </a>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                        >
                            {isLoading ? 'Signing in...' : 'Sign in'}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default VendorLogin;
