import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  Skeleton,
} from '@mui/material';
import { AiOutlineShoppingCart, AiOutlineHome } from 'react-icons/ai';

const OrderDetail = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const orderData = {
    orderId: 'ORD123456',
    orderDate: 'August 30, 2024',
    status: 'Shipped',
    total: '$120.00',
    items: [
      {
        id: 1,
        name: 'Product 1',
        quantity: 2,
        price: '$40.00',
        image: 'https://via.placeholder.com/100',
      },
      {
        id: 2,
        name: 'Product 2',
        quantity: 1,
        price: '$40.00',
        image: 'https://via.placeholder.com/100',
      },
    ],
    billingAddress: '1234 Elm St, Springfield, IL, 62701',
    shippingAddress: '1234 Elm St, Springfield, IL, 62701',
  };

  return (
    <div className="min-h-screen bg-gray-100 px-6 py-16 flex justify-center items-center">
      <div className="max-w-4xl w-full">
        <Card className="shadow-lg rounded-lg">
          <CardContent>
            {loading ? (
              <div>
                <Skeleton variant="text" width={200} height={40} />
                <Skeleton variant="rectangular" height={300} />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center mb-6">
                  <Typography variant="h5" className="font-bold text-gray-700">
                    Order Details
                  </Typography>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#D2691E', color: 'white' }}
                    startIcon={<AiOutlineShoppingCart />}
                  >
                    Reorder
                  </Button>
                </div>

                <Divider className="mb-6" />

                <div className="mb-6">
                  <Typography
                    variant="h6"
                    className="font-bold text-xl"
                    style={{ color: '#A0522D', borderBottom: '2px solid #CD853F' }}
                    className="mb-2 pb-1"
                  >
                    Order ID: {orderData.orderId}
                  </Typography>
                  <Typography className="text-gray-600">
                    Date: {orderData.orderDate}
                  </Typography>
                  <Typography className="text-gray-600">
                    Status: {orderData.status}
                  </Typography>
                  <Typography className="text-gray-600">
                    Total: {orderData.total}
                  </Typography>
                </div>

                <Divider className="mb-6" />

                <div className="mb-6">
                  <Typography
                    variant="h6"
                    className="font-bold text-xl"
                    style={{ color: '#A0522D', borderBottom: '2px solid #CD853F' }}
                    className="mb-4 pb-1"
                  >
                    Items in this Order
                  </Typography>
                  <div className="space-y-4">
                    {orderData.items.map((item) => (
                      <Card
                        key={item.id}
                        className="flex p-4 rounded-lg shadow-sm"
                      >
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-24 h-24 object-cover rounded-lg mr-4"
                        />
                        <div>
                          <Typography
                            variant="body1"
                            className="font-semibold text-gray-700"
                          >
                            {item.name}
                          </Typography>
                          <Typography className="text-gray-600">
                            Quantity: {item.quantity}
                          </Typography>
                          <Typography className="text-gray-600">
                            Price: {item.price}
                          </Typography>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>

                <Divider className="mb-6" />

                <div className="mb-6">
                  <Typography
                    variant="h6"
                    className="font-bold text-xl"
                    style={{ color: '#A0522D', borderBottom: '2px solid #CD853F' }}
                    className="mb-4 pb-1"
                  >
                    Billing Address
                  </Typography>
                  <div className="flex items-start">
                    <AiOutlineHome style={{ color: '#A0522D' }} className="text-2xl mr-2" />
                    <Typography className="text-gray-600">
                      {orderData.billingAddress}
                    </Typography>
                  </div>
                </div>

                <Divider className="mb-6" />

                <div className="mb-6">
                  <Typography
                    variant="h6"
                    className="font-bold text-xl"
                    style={{ color: '#A0522D', borderBottom: '2px solid #CD853F' }}
                    className="mb-4 pb-1"
                  >
                    Shipping Address
                  </Typography>
                  <div className="flex items-start">
                    <AiOutlineHome style={{ color: '#A0522D' }} className="text-2xl mr-2" />
                    <Typography className="text-gray-600">
                      {orderData.shippingAddress}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default OrderDetail;
