import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Breadcrumb } from 'antd';
import { handleGetAllCart } from '../../contextApi/api';
import CartItem from './CartItem'; // Assuming CartItem is in the same directory
import { Box, Typography, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CartPage = () => {
  const [loading, setLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  const fetch = async () => {
    try {
      setLoading(true);
      const res = await handleGetAllCart(); // Your API call here
      if (res?.data?.data) {
        setCartProducts(res.data.data.cart);
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setLoading(false);
    }
  };

  const removeItem = (id) => {
    setCartProducts(cartProducts.filter(product => product.id !== id));
  };

  useEffect(() => {
    fetch();
  }, []);

  // Calculate Total Price
  const calculateTotal = () => {
    try {
      const total = cartProducts.reduce((acc, product) => {
        // Ensure price is a number and default to 0 if not
        const price = parseFloat(product?.price) || 0;
        // Ensure pcs is a number and default to 0 if not
        const pcs = product?.pcs || 0;

        return acc + price * pcs;
      }, 0);

      return total.toFixed(2); // Format the result to 2 decimal places
    } catch (error) {
      console.error('Error calculating total:', error);
      return '0.00'; // Return '0.00' in case of an error
    }
  };

  const handleCheckout = () => {
    navigate('/checkout', { state: { cartProducts, total: calculateTotal() } });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-200 to-white px-4 sm:px-6 lg:px-8 py-6 sm:py-10">
      {/* Breadcrumb Section */}
      <div className="max-w-5xl mx-auto mb-4 pl-2 sm:pl-6">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/')}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>Cart</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6">
        {/* Left Section - Cart Items */}
        <div className="w-full sm:w-2/3">
          <div className="bg-gray-100 rounded-lg p-4 sm:p-6">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">Shopping Bag</h2>
            <div className="space-y-4">
              {loading ? (
                Array(2).fill().map((_, i) => (
                  <Skeleton key={i} height={100} className="rounded-lg" />
                ))
              ) : cartProducts.length > 0 ? (
                cartProducts.map(product => (
                  <CartItem
                    key={product.id}
                    product={product}
                    onRemove={removeItem}
                  />
                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  py={5}
                  sx={{ textAlign: 'center', backgroundColor: '#f5f5f5', borderRadius: '10px' }}
                >
                  <ShoppingCartIcon sx={{ fontSize: 80, color: '#9e9e9e', mb: 2 }} />
                  <Typography variant="h5" gutterBottom>
                    Your cart is empty
                  </Typography>
                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    Looks like you haven't added anything to your cart yet.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate('/')}
                    sx={{ mt: 3 }}
                  >
                    Continue Shopping
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </div>

        {/* Right Section - Summary and Shipping */}
        {cartProducts.length > 0 && (
          <div className="w-full sm:w-1/3 space-y-4">
            <div className="bg-gray-100 rounded-lg p-4 sm:p-6">
              <h3 className="text-lg sm:text-xl font-bold mb-4">Cart Total</h3>
              {loading ? (
                <Skeleton height={30} width="100%" />
              ) : (
                <>
                  <div className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span>Cart Subtotal</span>
                      <span>${calculateTotal()}</span>
                    </div>
                    <div className="flex justify-between text-sm font-bold">
                      <span>Total</span>
                      <span>${calculateTotal()}</span>
                    </div>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleCheckout}
                    >
                      Checkout
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartPage;
