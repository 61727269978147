import React, { useEffect, useState } from 'react';
import { Button, Avatar, TextField, IconButton, Tooltip, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LogoutIcon from '@mui/icons-material/Logout';
import 'tailwindcss/tailwind.css';
import { useNavigate } from 'react-router-dom';
import { handleGetProfile } from '../../contextApi/api';

const useStyles = makeStyles({
    button: {
        backgroundColor: '#6b46c1',
        '&:hover': {
            backgroundColor: '#5a3aa7',
        },
    },
    avatar: {
        width: '100px',
        height: '100px',
    },
    iconButton: {
        color: '#6b46c1',
        '&:hover': {
            color: '#5a3aa7',
        },
    },
    saveButton: {
        backgroundColor: '#4caf50',
        '&:hover': {
            backgroundColor: '#45a049',
        },
        '&:disabled': {
            backgroundColor: '#a5d6a7',
            color: '#fff',
        },
    },
    disabledInput: {
        backgroundColor: '#f5f5f5',
        color: '#9e9e9e',
    },
    logoutButton: {
        color: '#ff1744',
        '&:hover': {
            color: '#d50000',
        },
    },
});

const ProfilePage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const fetchProfile = async () => {
        try {
            const res = await handleGetProfile();
            if (res.data.status) {
                setProfile(res.data.data);
            }
        } catch (error) {
            console.error('Failed to fetch profile', error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveChanges = () => {
        // Handle save changes logic here
        setIsEditing(false);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/auth/login');
    };

    if (!profile) {
        return (
            <div className="flex flex-col items-center bg-gray-100 min-h-screen p-6">
                {/* Profile Header Skeleton */}
                <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-6">
                    <div className="flex items-center justify-center">
                        <Skeleton variant="circular" width={100} height={100} />
                    </div>
                    <div className="text-center mt-4">
                        <Skeleton variant="text" width="60%" height={32} />
                        <Skeleton variant="text" width="40%" height={24} />
                        <Skeleton variant="rectangular" width="50%" height={36} className="mt-4 mx-auto" />
                    </div>

                    {/* Cart, Orders, and Balance Section Skeleton */}
                    <div className="flex justify-around mt-6">
                        <Skeleton variant="circular" width={48} height={48} />
                        <Skeleton variant="circular" width={48} height={48} />
                        <Skeleton variant="circular" width={48} height={48} />
                    </div>
                </div>

                {/* Profile Details Form Skeleton */}
                <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-6 mt-6">
                    <form className="space-y-4">
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={48} />
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center bg-gray-100 min-h-screen p-6">
            {/* Profile Header */}
            <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-6 relative">
                {/* Logout Button */}
                <div className="absolute top-4 right-4">
                    <Tooltip title="Logout" arrow>
                        <IconButton
                            className={classes.logoutButton}
                            onClick={handleLogout}
                        >
                            <LogoutIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="flex items-center justify-center">
                    <Avatar
                        alt={profile.name}
                        src={profile.image || '/profile-pic.jpg'} // Show the user's image if available
                        className={`${classes.avatar} shadow-md`}
                    />
                </div>
                <div className="text-center mt-4">
                    <h2 className="text-2xl font-semibold text-gray-800">{profile.name}</h2>
                    <p className="text-gray-600">{profile.email}</p>
                    <Button
                        variant="contained"
                        className={`${classes.button} mt-4`}
                        disableElevation
                        onClick={handleEditClick}
                    >
                        Edit Profile
                    </Button>
                </div>

                {/* Cart, Orders, and Balance Section */}
                <div className="flex justify-around mt-6">
                    <Tooltip title="View Cart" arrow>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => navigate('/cart')}
                        >
                            <ShoppingCartIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Orders" arrow>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => navigate('/orders')}
                        >
                            <ListAltIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Balance" arrow>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => navigate('/transaction')}
                        >
                            <AccountBalanceWalletIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {/* Profile Details Form */}
            <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-6 mt-6">
                <form className="space-y-4">
                    <TextField
                        fullWidth
                        label="Full Name"
                        variant="outlined"
                        defaultValue={profile.name}
                        InputProps={{
                            readOnly: !isEditing,
                            classes: { root: !isEditing ? classes.disabledInput : '' },
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        defaultValue={profile.email}
                        InputProps={{
                            readOnly: !isEditing,
                            classes: { root: !isEditing ? classes.disabledInput : '' },
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        defaultValue={profile.mobile}
                        InputProps={{
                            readOnly: !isEditing,
                            classes: { root: !isEditing ? classes.disabledInput : '' },
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Address"
                        variant="outlined"
                        defaultValue={profile.address}
                        InputProps={{
                            readOnly: !isEditing,
                            classes: { root: !isEditing ? classes.disabledInput : '' },
                        }}
                    />
                    <TextField
                        fullWidth
                        label="City"
                        variant="outlined"
                        defaultValue={profile.city}
                        InputProps={{
                            readOnly: !isEditing,
                            classes: { root: !isEditing ? classes.disabledInput : '' },
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Zip Code"
                        variant="outlined"
                        defaultValue={profile.zipcode}
                        InputProps={{
                            readOnly: !isEditing,
                            classes: { root: !isEditing ? classes.disabledInput : '' },
                        }}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        className={`${classes.saveButton}`}
                        disableElevation
                        disabled={!isEditing}
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ProfilePage;
