import React from 'react';
import Pagination from '@mui/material/Pagination';

const PaginationComponent = ({ totalPages, currentPage, onPageChange }) => {

  const handleChange = (event, value) => {
    onPageChange(value);  // Call the parent function to update the current page in the parent
  };

  return (
    <div>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        boundaryCount={2}
        variant="outlined"
        shape="rounded"
        sx={{
            '& .MuiPaginationItem-root': {
              height: '50px',  // Adjust the height as needed
              minWidth: '50px',  // Adjust the minimum width as needed
              fontSize: '16px',  // Optional: Adjust font size for better readability
            }
          }}  
      />
      {/* <Pagination count={5}  siblingCount={0} /> */}
    </div>
  );
};

export default PaginationComponent;
