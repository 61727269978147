import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Slide } from '@mui/material';


function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

const CustomSnackbar = ({ open, handleClose, message, duration }) => {
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message={message}
      TransitionComponent={SlideTransition}
      key="no-transition"
      autoHideDuration={duration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: '#6200ea', // Example: Purple background
          color: '#fff',
          fontSize: '1rem',
          borderRadius: '8px',
          padding: '10px',
        },
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default CustomSnackbar;
