import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

const TooltipIcon = ({ title, icon: Icon, link, badge }) => {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        component={link ? Link : 'button'}
        to={link}
        className="relative hover:bg-gray-200 p-2 rounded-full transition duration-300 ease-in-out"
      >
        <Icon className="h-6 w-6 text-black" />
        {badge && (
          <span className="absolute top-0 right-0 bg-red-500 text-xs text-white rounded-full h-4 w-4 flex items-center justify-center">
            {badge}
          </span>
        )}
      </IconButton>
    </Tooltip>
  );
};

export default TooltipIcon;
