import React, { useState } from 'react';
import ImageZoom from 'react-image-zooom';
import { AiOutlineClose } from 'react-icons/ai';

const ImageZoomModal = ({ isOpen, onClose, imageSrc }) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close modal if clicked outside the modal content
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleBackdropClick} // Detect click outside the modal
    >
      <div
        className="relative bg-white rounded-lg shadow-lg p-4 w-full max-w-4xl h-auto max-h-[80vh] overflow-y-auto"
        style={{ scrollbarWidth: "none" }}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-red-500"
          onClick={onClose}
        >
          <AiOutlineClose />
        </button>
        {/* Image Zoom */}
        <div className="p-4">
          <ImageZoom
            src={imageSrc}
            alt="Zoomable"
            zoom="200%"
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageZoomModal;
