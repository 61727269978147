import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import Filters from "../../../component/Filter";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import the skeleton CSS
import { handleGetAllProducts } from "../../../contextApi/api";
import { URL } from "../../../contextApi/urls";
import PaginationComponent from "../../../component/PaginationComponent/PaginationComponent";

const ProductList = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage,setTotalPage] = useState(0)
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update the current page in the parent component
        
        // Smoothly scroll to the top of the page
        window.scrollTo({
          top: 0, // Scroll to the top of the page
          behavior: "smooth" // Enable smooth scrolling
        });
      };
      

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filtering, setFiltering] = useState(false); // New filtering loading state
    const [filters, setFilters] = useState({
        categories: [],
        variants: [],
    });

    console.log(filteredProducts,"filteredProducts");
    

    // Fetching products data
    const fetchProducts = async () => {
        try {
            setLoading(true);
            const res = await handleGetAllProducts(currentPage);
            if (res?.data?.data) {
                setProducts(res.data.data.data);
                setTotalPage(res.data.data?.last_page)
                setFilteredProducts(res.data.data.data);
            }
        } catch (error) {
            console.error('Failed to fetch products:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage]);

    useEffect(() => {
        if (filters.categories.length > 0 || filters.variants.length > 0) {
            setFiltering(true); // Start filtering
            setTimeout(() => {
                filterProducts();
                setFiltering(false); // End filtering after 100ms
            }, 500);
        } else {
            filterProducts(); // Immediate filtering without delay if no filters are selected
        }
    }, [filters]);

    const filterProducts = () => {
        let filtered = products;

        if (filters.categories.length > 0) {
            filtered = filtered.filter((product) =>
                filters.categories.includes(product.categoryname?.name)
            );
        }

        if (filters.variants.length > 0) {
            filtered = filtered.filter((product) =>
                filters.variants.includes(product.variationname?.name)
            );
        }

        setFilteredProducts(filtered);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <>
        <div className="px-4 sm:px-6 py-20">
            <h2 className="text-2xl sm:text-4xl font-extrabold text-center text-gray-900 mb-10">
                Elegant Jewelry Collection
            </h2>
            <div className="flex flex-col sm:flex-row">
                {/* Filters on the left side for larger screens, top for mobile */}
                <div className="w-full sm:w-1/4 sm:flex-shrink-0 sm:sticky sm:top-5 mb-4 sm:mb-0 h-auto sm:h-screen overflow-y-auto">
                    <Filters onFilterChange={handleFilterChange} />
                </div>

                {/* Product list on the right side */}
                <div className="w-full sm:w-3/4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {loading || filtering
                            ? Array.from({ length: 4 }).map((_, index) => (
                                <div key={index} className="p-4">
                                    <Skeleton height={200} />
                                    <Skeleton count={2} />
                                </div>
                            ))
                            : filteredProducts.map((product, index) => (
                              <ProductCard
                                key={index}
                                image={`${URL.BASE_URL}${product?.productselected?.featureimage}`}
                                title={product?.productselected?.name}
                                price={product?.mrp}
                                productId={product?.id}
                                categoryName={product?.categoryname?.name}
                                variationName={product?.variationname?.name}
                                product={product} // Pass the entire product object here
                              />
                            ))}
                    </div>
                </div>
            </div>
            </div>
           
        {/* Pagination */}
        <div className="flex justify-center pb-10 pl-5">
         <PaginationComponent 
         totalPages={totalPage} 
         currentPage={currentPage} 
         onPageChange={handlePageChange} 
         />
       </div>
       </>
    );
};

export default ProductList;
