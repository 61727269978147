import axios from "axios";
import { URL } from "./urls";
import {
    setToken,
    getToken
} from "./token";

export const api = axios.create({
    baseURL: URL.BASE_URL,
});


const unauthError = (statusCode) => {
    localStorage.clear();
    // if(statusCode){
        window.location.href = '/auth/login'
    // }
}


// check login status
export const handleIsLogin = async () => {
    try {
        const res = await api.get(URL.IS_LOGIN, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
       
        return res;
    } catch (error) {
        unauthError()
        unauthError();  console.error(error);;
    }
}

// login
export const handleLogin = async (data) => {
    try {
        const res = await api.post(URL.VENDOR_LOGIN, data);
        
        if(res?.data?.api_token){
            setToken(res?.data?.api_token)
        }
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}
// add cart items
export const handleAddCart = async (data) => {
    try {
        const res = await api.post(URL.ADD_CART, data,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
            } 
        );
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}

export const handleRemoveCart = async (data) => {
    try {
        const res = await api.post(URL.REMOVE_CART, data,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
            } 
        );
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}

export const handleGetAllCart = async () => {
    try {
        const res = await api.get(URL.GET_CART,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
            } 
        );
        
        return res;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}

export const handlegetAllCategories = async () => {
    try {
        const res = await api.get(URL.GET_ALL_CATEGORIES,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
            } 
        );
        
        return res;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}

export const handlegetAllVarients = async () => {
    try {
        const res = await api.get(URL.GET_ALL_VARIANTS,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
            } 
        );
        
        return res;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}


export const handleGetProfile = async () => {
    try {
        const res = await api.get(URL.GET_PROFILE,
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
            } 
        );
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}

//  users
export const handleGetUsers = async ({ page, search }) => {
    try {
        console.log(page, search);

        const res = await api.get(`${URL.GET_USERS}?page=${page}${search && `&query=${search}`}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}


// Vendor

export const handleGetVendors = async ({ page, search, type }) => {
    try {
        const res = await api.get(`${URL.GET_VENDORS}?page=${page}&type=${type}&name=${search}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}

export const handleAddVendor = async (data) => {
    try {
        const res = await api.post(URL.CREATE_VENDOR, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            },
        });
        
        return res;
    } catch (error) {
       unauthError(); 
        console.error(error);
    }
}


// vendor details

export const handleGetVendorDetails = async (id) => {
    try {
        const res = await api.get(`${URL.GET_VENDOR_DETAILS}?vendor_id=${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res
    } catch (error) {
        unauthError(); 
        console.error(error);
    }
}

export const handleAddVendorDocs = async (data) => {
    try {
        const res = await api.post(URL.ADD_VENDOR_DOCS, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}

export const handleAddBookingVendor = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_BOOKING_VENDOR}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res;
    } catch (error) {
       unauthError();  console.error(error);
    }
}

// getVendorTransaction
export const handleGetVendorTransactions = async (id) => {
    try {
        const res = await api.get(`${URL.GET_VENDOR_TRANSACTION}?vendor_id=${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res
    } catch (error) {
        unauthError(); 
        console.error(error);
    }
}

export const handleGetVendorDocs = async (id) => {
    try {
        const res = await api.get(`${URL.GET_VENDOR_DOCS}?vendor_id=${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleAddVendorDocuments = async (data) => {
    try {
        const res = await api.post(URL.ADD_VENDOR_DOCUMENTS, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

// createVendortransaction 

export const handleCreateVendortransaction = async (data) => {
    try {
        const res = await api.post(`${URL.CREATE_VENDORTRANSACTION}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}


// city

export const handleAddCity = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_CITY}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdateCity = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_CITY}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetAllCities = async ({ page, search }) => {
    try {
        const res = await api.get(`${URL.GET_CITIES}?page=${page}${search && '&query=' + search}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeleteCity = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_CITY}`, { id }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}



//////pincode//////
export const handleAddPincode = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_PINCODE}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetPincodes = async ({ page, search }) => {
    try {
        const res = await api.get(`${URL.GET_PINCODES}?page=${page}${search && '&query=' + search}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const getCitywisePincode = async ({ page, search, city_id }) => {
    try {
        const res = await api.get(`${URL.getCitywisePincode}?page=${page}${search && '&query=' + search}${city_id && '&city_id=' + city_id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetActivePincodes = async (page) => {
    try {
        const res = await api.get(`${URL.GET_ACTIVE_PINCODES}${page && '?page=' + page}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdatePincode = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_PINCODE}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeletePincode = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_PINCODE}`, { id }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}



//////tax//////

export const handleAddTax = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_TAX}`, data, {
            headers: {

                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetTaxes = async ({ page, search }) => {
    try {
        const res = await api.get(`${URL.GET_TAX}?page=${page}${search && '&query=' + search}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetActiveTaxes = async (page) => {
    try {
        const res = await api.get(`${URL.GET_ACTIVE_TAX}${page && '?page=' + page}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdateTax = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_TAX}`, data, {
            headers: {

                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const getCityWiseActiveTaxes = async ({ page, search, city_id }) => {
    try {
        const res = await api.get(`${URL.getCityWiseTaxes}?page=${page}${search && '&query=' + search}${city_id && '&city_id=' + city_id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeleteTax = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_TAX}`, { id }, {
            headers: {

                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

// Category

export const handleAddCategory = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_CATEGORY}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdateCategory = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_CATEGORY}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetAllCategories = async ({ page, search }) => {
    try {
        const res = await api.get(`${URL.GET_ALL_CATEGORIES}?page=${page}${search && '&query=' + search}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}




export const handleGetProductDetails = async (data) => {
    try {
        const res = await api.post(`${URL.GET_PRODUCT_DETAILS}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
                // Authorization: `Bearer 115|Gbdtidjl92UhEKWHzCdP2rTiB1J1s1Qgynhaw60Hf56591ba`
            }
        });
        
        return res
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}
export const handleGetProductsImages = async (id) => {
    try {
        const res = await api.get(`${URL.GET_PRODUCT_DETAIL_IMAGES}?product_id=${id}`,{
            headers: {
                Authorization: `Bearer ${getToken()}`
                // Authorization: `Bearer 115|Gbdtidjl92UhEKWHzCdP2rTiB1J1s1Qgynhaw60Hf56591ba`
            }
        });

        return res
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}
export const handleGetActiveCategories = async (data) => {
    try {
        const res = await api.post(`${URL.GET_ACTIVE_CATEGORIES}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeleteCategory = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_CATEGORY}`, { id }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}


//////Product//////
export const handleAddProduct = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_PRODUCT}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleEditProduct = async (data) => {
    try {
        const res = await api.post(`${URL.EDIT_PRODUCT}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeleteProduct = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_PRODUCT}`, { id }, {
            headers: {

                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetAllProducts = async (page) => {
    try {
        const res = await api.get(`${URL.GET_ALL_PRODUCTS}?page=${page || 1}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
                // Authorization: `Bearer 115|Gbdtidjl92UhEKWHzCdP2rTiB1J1s1Qgynhaw60Hf56591ba`
            }
        });
        console.log("resresres",res);
        if (res.status === 401) {
            unauthError(res.status); // Call unauthError with status code
        }
        return res
    } catch (error) {
        if (error.response && error.response.status === 401) {
            unauthError(error.response.status); // Call unauthError with status code
        } else {
            console.error(error);
        }
    }
}

// get details
// export const handleGetProductDetails = async (id) => {
//     try {
//         const res = await api.get(`${URL.GET_PRODUCT_DETAILS}?id=${id}`, {
//             headers: {
//                 Authorization: `Bearer ${getToken()}`
//             }
//         });
        
//         return res
//     } catch (error) {
//         unauthError();  console.error(error);
//     }
// }

export const handleAddProductImages = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_PRODUCT_IMAGES}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleGetProductImages = async (id) => {
    try {
        const res = await api.get(`${URL.GET_PRODUCT_IMAGES}?product_id=${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`

            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeleteImage = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_IMAGE}`, { id }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdateImage = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_IMAGE}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleBulkUpdateImages = async (data) => {
    try {
        const res = await api.post(`${URL.BULK_UPDATE_IMAGES}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleBulkDeleteImages = async (data) => {
    try {
        const res = await api.post(`${URL.BULK_DELETE_IMAGES}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}


//////skus//////
export const handleCreateSku = async (data) => {
    try {
        const res = await api.post(`${URL.CREATE_SKU}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdateSku = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_SKU}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleDeleteSku = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_SKU}`, { id }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}


// varients

export const handleCreateVarient = async (data) => {
    try {
        const res = await api.post(`${URL.ADD_VARIANTS}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

export const handleUpdateVarient = async (data) => {
    try {
        const res = await api.post(`${URL.UPDATE_VARIANTS}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}


export const handleDeleteVarient = async (id) => {
    try {
        const res = await api.post(`${URL.DELETE_VARIANTS}`, { id }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        
        return res
    } catch (error) {
        unauthError();  console.error(error);
    }
}

// export const handleGetAllVarient = async ({ page, search }) => {
//     try {
//         const res = await api.get(`${URL.GET_ALL_VARIANTS}?page=${page}${search && '&query=' + search}`, {
//             headers: {
//                 Authorization: `Bearer ${getToken()}`
//             }
//         });
        
//         return res
//     } catch (error) {
//         unauthError();  console.error(error);
//     }
// }

