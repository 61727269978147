import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Tag, Divider, Breadcrumb, Descriptions, Button, InputNumber, Alert, Space } from 'antd';
import { ShoppingCartOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { URL } from '../../contextApi/urls';
import { handleGetProductDetails, handleAddCart, handleGetProductsImages } from '../../contextApi/api';
import ImageZoomModal from '../../component/ImageZoom/ImageZoom';
const { Title, Text, Paragraph } = Typography;

const ProductDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Fetch product ID from URL params
    const [isZoomModalOpen, setisZoomModalOpen] = useState(false);
    const handleOpenZoomModal = () => {
        setisZoomModalOpen(true);
      };
    
      const handleCloseZoomModal = () => {
        setisZoomModalOpen(false);
      };



    const [product, setProduct] = useState(null);
    const [productLargeImage,setproductLargeImage] = useState(null)
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [addToCartLoading, setAddToCartLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [imagesLoading, setImagesLoading] = useState(true); // New state to track product images loading
    console.log(productImages,"productImages");
    
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                setLoading(true);
                const dataToSend = { id };
                const res = await handleGetProductDetails(dataToSend);
                
                if (res?.data?.data) {
                    const productID = res.data.data?.product_id;
                    setProduct(res.data.data);
                    setproductLargeImage(res.data.data?.product?.featureimage);
                    handleGetImagesByProduct(productID); // Fetch product images after product details

                } else {
                    setError('Product data is not available. Please try again later.');
                }
            } catch (error) {
                setError('Failed to fetch product details.');
                console.error('Failed to fetch products:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchProductDetails();
    }, [id]);

    const handleGetImagesByProduct = async (pid) => {
        try {
            // const pid = product?.product_id || '';
            setImagesLoading(true); // Start images loading
            const res = await handleGetProductsImages(pid);
            console.log("resresres",res);
            
            if (res?.data?.data) {
                setProductImages(res.data.data);
            } else {
                // setError('Product images are not available. Please try again later.');
            }
        } catch (error) {
            setError('Failed to fetch product images.');
            console.error('Failed to fetch product images:', error);
        } finally {
            setImagesLoading(false); // Stop images loading
        }
    };

    const handleQuantityChange = value => {
        setQuantity(value);
    };

    const addToCart = async () => {
        try {
            setAddToCartLoading(true); // Start loading and disable button
            const cartItem = {
                products: [
                    {
                        skus_id: product?.id || '',
                        pcs: quantity
                    }
                ]
            };

            const res = await handleAddCart(cartItem);
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                setQuantity(quantity); // You can reset or update the quantity as needed
            } else {
                toast.error(res?.data?.message);
            }
        } catch (error) {
            console.error('Failed to add to cart:', error);
            toast.error("Failed to add to cart");
        } finally {
            setAddToCartLoading(false); // Stop loading and re-enable the button
        }
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const getShortDescription = (description) => {
        const wordLimit = 100;
        const words = description.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return description;
    };

    if (error) {
        return (
            <div className="p-4">
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    showIcon
                    action={
                        <Button size="small" type="primary" onClick={() => navigate('/dashboard/products')}>
                            Back to Products
                        </Button>
                    }
                />
            </div>
        );
    }



    const handleProudctSmallImageClick = (image) => {
        setproductLargeImage(image);
        // Add any additional functionality here, such as setting the main product image
    }; 

    return (
        <>
        <div className="px-4 md:px-12 lg:px-24 py-10">
            <Breadcrumb className="mb-4">
                <Breadcrumb.Item onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('/dashboard/products')} style={{ cursor: 'pointer' }}>Products</Breadcrumb.Item>
                <Breadcrumb.Item>Product Details</Breadcrumb.Item>
            </Breadcrumb>

            <Card bordered={false} className="shadow-lg">
                <Row gutter={[32, 32]}>
                    <Col xs={24} md={12}>
                        {loading ? (
                            <Skeleton height={400} />
                        ) : (
                            <>
                                <Card
                                    cover={
                                        <img
                                            // src={`${URL.BASE_URL}${product?.product?.featureimage || ''}`}
                                            src={`${URL.BASE_URL}${productLargeImage || ''}`}
                                            alt={productLargeImage ? 'Product Image' : 'Default Image'}
                                            style={{ borderRadius: '12px', objectFit: 'cover', maxHeight: '400px',height:"390px" }}
                                      
                                            onClick={handleOpenZoomModal}
                                            />
                                    }
                                    bordered={false}
                                />
                                <Row gutter={[8, 8]} className="mt-2">
                                    {imagesLoading ? (
                                        [1, 2, 3].map((_, index) => (
                                            <Col key={index} span={8}>
                                                <Skeleton height={100} />
                                            </Col>
                                        ))
                                    ) : (
                                        productImages.map((item, index) => (
                                            <Col key={index} span={8}>
                                                <img
                                                    src={`${URL.BASE_URL}${item?.image}`}
                                                    alt={`Thumbnail ${index + 1}`}
                                                    style={{ 
                                                        borderRadius: '8px', 
                                                        objectFit: 'cover',
                                                         width: '100%',
                                                          height: '100px',
                                                          border: productLargeImage === item?.image ? '2px solid #007bff' : '2px solid transparent', // Highlight selected image
                                                        }}
                                                        onClick={() => handleProudctSmallImageClick(item?.image)} // Set the selected image on click
                                                />
                                            </Col>
                                        ))
                                    )}
                                </Row>
                            </>
                        )}
                    </Col>
                    <Col xs={24} md={12}>
                        {loading ? (
                            <>
                                <Skeleton height={40} width="80%" />
                                <Skeleton count={5} />
                            </>
                        ) : (
                            <>
                                <Title level={2}>{product?.product?.name || 'Product Name'}</Title>
                                <Paragraph>
                                    <Text strong>SKU ID: </Text>{product?.skuid || 'N/A'}
                                </Paragraph>
                                <Paragraph>
                                    <Text strong>Price: </Text>
                                    <Title level={3} style={{ color: '#f5222d' }}>₹ {product?.mrp ? product.mrp : 'N/A'}</Title>
                                </Paragraph>
                                <Paragraph>
                                    <Tag color={product?.stockqty > 0 ? 'green' : 'red'} style={{ marginBottom: '10px' }}>
                                        {product?.stockqty > 0 ? 'In Stock' : 'Out of Stock'}
                                    </Tag>
                                </Paragraph>
                                <Paragraph>
                                    <Text strong>Category: </Text>{product?.category?.name || 'N/A'}
                                </Paragraph>
                                <Paragraph>
                                    <Text strong>Variation: </Text>{product?.variation?.name || 'N/A'}
                                </Paragraph>
                                <Paragraph>
                                    <Text strong>Size: </Text>{product?.size || 'N/A'}
                                </Paragraph>

                                <Divider />

                                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                    <Space>
                                        <Text strong>Quantity: </Text>
                                        <Button icon={<MinusOutlined />} onClick={() => handleQuantityChange(Math.max(1, quantity - 1))} />
                                        <InputNumber
                                            min={1}
                                            max={product?.stockqty || 1}
                                            value={quantity}
                                            onChange={handleQuantityChange}
                                            style={{ width: '80px', textAlign: 'center' }}
                                        />
                                        <Button icon={<PlusOutlined />} onClick={() => handleQuantityChange(Math.min(product?.stockqty || 1, quantity + 1))} />
                                    </Space>

                                    <Button
                                        type="primary"
                                        icon={<ShoppingCartOutlined />}
                                        onClick={addToCart}
                                        size="large"
                                        style={{ width: '100%', marginTop: '20px' }}
                                        disabled={addToCartLoading || product?.stockqty <= 0} // Disable if loading or out of stock
                                    >
                                        Add to Cart
                                    </Button>
                                </Space>
                            </>
                        )}
                    </Col>
                </Row>
            </Card>

            <Row gutter={[16, 16]} className="mt-4">
                <Col span={24}>
                    <Card className="shadow-md">
                        {loading ? (
                            <>
                                <Skeleton height={30} width="40%" />
                                <Skeleton count={3} />
                            </>
                        ) : (
                            <>
                                <Title level={4}>Product Description</Title>
                                <Divider />
                                <div>
                                    {showMore
                                        ? <div dangerouslySetInnerHTML={{ __html: product?.product?.description || '<p>No description available.</p>' }} />
                                        : <div dangerouslySetInnerHTML={{ __html: getShortDescription(product?.product?.description || '') }} />}
                                    {product?.product?.description?.split(' ').length > 100 && (
                                        <Button type="link" onClick={toggleShowMore}>
                                            {showMore ? 'Show Less' : 'Show More'}
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-4">
                <Col span={24}>
                    <Card className="shadow-md">
                        {loading ? (
                            <Skeleton height={200} />
                        ) : (
                            <Descriptions title="Product Details" bordered column={1}>
                                <Descriptions.Item label="Weight">{product?.product?.weight || 'N/A'}</Descriptions.Item>
                                <Descriptions.Item label="Package Unit">{product?.product?.packageunit || 'N/A'}</Descriptions.Item>
                                <Descriptions.Item label="Closure">{product?.product?.closure || 'N/A'}</Descriptions.Item>
                            </Descriptions>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
        {/* Image Modal */}
      <ImageZoomModal
        isOpen={isZoomModalOpen}
        onClose={handleCloseZoomModal}
        imageSrc={`${URL.BASE_URL}${productLargeImage}`}
      />
        </>
    );
};

export default ProductDetail;
