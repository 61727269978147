

export const URL = {


    BASE_URL: "https://jewelleryonrent.com",
    // BASE_URL: "http://192.168.0.14:8000",

    VENDOR_LOGIN: "/api/vendor/vendor_login",
    IS_LOGIN: "/api/admin/checkAdminAuth",
    
    // vendor profile
    GET_PROFILE:"/api/vendor/profile",

    USER: "/user",
    LOGOUT: "/logout",
    ALL_USERS: "/users",


    // all categories
    GET_ALL_CATEGORIES: "/api/vendor/getAllCategories",

    //////vendors//////
    CREATE_VENDOR: "/api/admin/createVendor",
    ADD_VENDOR_DOCS: "/api/admin/addVendorDocument",
    GET_VENDOR_DOCS: "/api/admin/getVendorDocuments",
    ADD_BOOKING_VENDOR: "/api/admin/activeVendor",
    GET_VENDOR_DETAILS: "/api/admin/getVendorDetails",
    GET_VENDOR_TRANSACTION: "/api/admin/getVendorTransaction",
    ADD_VENDOR_DOCUMENTS: '/api/admin/addVendorDocument',
    CREATE_VENDORTRANSACTION: '/api/admin/createVendortransaction',

    // cart items 
    ADD_CART:"/api/vendor/add_vendor_cart",
    REMOVE_CART:"/api/vendor/remove_vendor_cart",
    GET_CART:"/api/vendor/get_vendor_cart",
    UPDATE_CART:"/",

    // clients
    GET_USERS: "/api/admin/getUsers",
    GET_VENDORS: "/api/admin/getvendors",

    //////city//////
    ADD_CITY: "/api/admin/addCity",
    GET_CITIES: "/api/admin/getAllCities",
    UPDATE_CITY: "/api/admin/updateCity",
    DELETE_CITY: '/api/admin/deleteCity',

    //////pincode//////
    ADD_PINCODE: "/api/admin/addPincode",
    GET_PINCODES: "/api/admin/getAllPincode",
    getCitywisePincode  : "/api/admin/getCitywisePincode",
    GET_ACTIVE_PINCODES: "/api/admin/getActivePincode",
    UPDATE_PINCODE: "/api/admin/updatePincode",
    DELETE_PINCODE: "/api/admin/deletePincode",



    //////tax//////
    ADD_TAX: "/api/admin/addTax",
    GET_TAX: "/api/admin/getAllTaxes",
    GET_ACTIVE_TAX: "/api/admin/getActiveTaxes",
    UPDATE_TAX: "/api/admin/updateTax",
    DELETE_TAX: "/api/admin/deleteTax",
    getCityWiseTaxes: "/api/admin/getCityWiseTaxes",

    //product detail images
    GET_PRODUCT_DETAIL_IMAGES: "/api/vendor/getImagesByProduct",




    //////Product//////
    // ADD_PRODUCT: '/api/admin/addProduct',
    // EDIT_PRODUCT: '/api/admin/editProduct',
    // DELETE_PRODUCT: '/api/admin/deleteDetails',

    GET_ALL_PRODUCTS: '/api/vendor/sku_product',

    GET_PRODUCT_DETAILS: '/api/vendor/sku_detail',

    ADD_PRODUCT_IMAGES: '/api/admin/addProductImages',
    GET_PRODUCT_IMAGES: '/api/admin/getImagesByProduct',
    DELETE_IMAGE: '/api/admin/deleteImage',
    UPDATE_IMAGE: '/api/admin/updateImage',
    BULK_UPDATE_IMAGES: '/api/admin/bulkUpdateImages',
    BULK_DELETE_IMAGES: '/api/admin/bulkDeleteImages',

    //////skus//////
    CREATE_SKU: '/api/admin/createSku',
    UPDATE_SKU: '/api/admin/skus',
    DELETE_SKU: '/api/admin/deleteSku',


    //////category//////
    // ADD_CATEGORY: "/api/admin/addCategory",
    // UPDATE_CATEGORY: "/api/admin/updateCategory",
    // GET_ALL_CATEGORIES: "/api/admin/getAllCategories",
    // GET_ACTIVE_CATEGORIES: "/api/admin/getActiveCategories",
    // DELETE_CATEGORY: "/api/admin/deleteCategory",


    // varients
    // ADD_VARIANTS: "/api/admin/addVariants",
    GET_ALL_VARIANTS: "/api/vendor/getAllVariants",
    // GET_ACTIVE_VARIANTS: "/api/admin/getActiveVariants",
    // UPDATE_VARIANTS: "/api/admin/updateVariants",
    // DELETE_VARIANTS: "/api/admin/deleteVariants",


};