import * as React from 'react';
import Button from '@mui/material/Button';
import CustomSnackbar from '.'; // Make sure the path to CustomSnackbar is correct

export default function TriggerButton() {
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: '',
    duration: 2500,
  });

  const handleClick = () => {
    setSnackbarState({
      open: true,
      message: 'This is a Snackbar without Transition',
      duration: 800,
    });
  };

  const handleClose = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <div>
      <Button onClick={handleClick}>
        Show Snackbar
      </Button>

      <CustomSnackbar
        open={snackbarState.open}
        handleClose={handleClose}
        message={snackbarState.message}
        duration={snackbarState.duration}
      />
    </div>
  );
}
