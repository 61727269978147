import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import Navbar from "./component/navbar";
import DigitalInfluencerSection from "./screens/dashboard";
import CartPage from "./screens/cart";
import Product_Details from "./screens/productDetails";
import OrdersPage from "./screens/orders";
import VendorLogin from "./screens/login";
import { ToastContainer } from "react-toastify";
import TriggerButton from "./component/CustomSnackbar/TriggerButton";
import CheckoutPage from "./screens/CheckOutPage";
import OrderSuccess from "./screens/OrderSuccess";
import OrderDetail from "./screens/OrderDetail";
import ProfilePage from "./screens/ProfilePage";
import Transaction from "./screens/transactions";

function App() {
  // Custom hook to get the current location
  const location = useLocation();

  return (
    <>
      {/* Conditionally render Navbar only if the current path is not "/auth/login" */}
      {location.pathname !== "/auth/login" && <Navbar />}
      
      {/* Add padding to the top of the content to account for the fixed Navbar */}
      {/* <div className={location.pathname !== "/auth/login" ? "pt-28" : ""}> */}
        <Routes>
          <Route path="/dashboard" element={<DigitalInfluencerSection />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/product_details/:id" element={<Product_Details />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/order_details/:id" element={<OrderDetail />} />
          <Route path="/auth/login" element={<VendorLogin />} />
          <Route path="/snackbar" element={<TriggerButton />} />

          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/ordersuccess" element={<OrderSuccess />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      {/* </div> */}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
      <ToastContainer/>
    </Router>
  );
}

export default AppWrapper;
